import { tailwind as baseStyles } from "@theme-ui/presets"
import { merge } from "lodash"

export default merge(baseStyles, {
  breakpoints: [
    "280px", // z-filp
    "377px", // iphone x
    "415px", // iphone 7 plus
    "770px", // ipad
    "1025px", // ipad pro
    "1400px", // other
  ],
  fonts: {
    body:
      'Cairo, Rubik, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    heading: "Cairo, Rubik, sans-serif",
    monospace: "Menlo, monospace",
  },
  colors: {
    primary: "#ceab62",
    primaryLight: "#ecd561",
    primaryDark: "#b18d34",
    secondary: "#30383c",
    secondaryTransparent: "#30383c73",
    background: "#fff",
    text: "#333",
    transparentBackground: "#ffffffab",
    modes: {
      dark: {
        primary: "#ceab62",
        secondary: "#30383c",
        background: "#000",
        text: "#fff",
        transparentBackground: "#ffffff14",
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: "heading",
      fontSize: 1,
      display: "inline-block",
      fontWeight: "bold",
      color: "background",
      bg: "primary",
      paddingY: "6px",
      borderRadius: "20px",
      outline: "none",
      cursor: "pointer",
      transition: "0.4s",
      ":hover": {
        opacity: "0.8",
        color: "primary",
      },
    },
    secondary: {
      fontFamily: "heading",
      variant: "buttons.primary",
      backgroundColor: "secondary",
      color: "background",
    },
    white: {
      fontFamily: "heading",
      variant: "buttons.primary",
      backgroundColor: "white",
      color: "text",
    },
    navStyle: {
      variant: "buttons.primary",
      bg: "transparent",
      border: "1px solid",
      borderRadius: "20px",
      p: "3px 18px",
      fontSize: "14px",
    },
  },
  cards: {
    primary: {
      boxShadow: "0 2px 15px 0px rgba(0,0,0,0.07)",
    },
  },
  images: {
    logo: {
      width: ["30px", "50px"],
      height: ["30px", "50px"],
    },
  },
  text: {
    small: {
      fontSize: 13,
      fontWeight: 500,
    },
    heading: {
      color: "primary",
    },
  },
  links: {
    nav: {
      color: "text",
      textDecoration: "none",
      fontSize: 14,
      fontWeight: 600,
      p: "5px 10px",
      marginInlineEnd: "4px",
      transition: "0.4s",
      display: "inline-block",
      ":hover": {
        opacity: "0.8",
        color: "primaryDark",
      },
    },
  },
  styles: {
    a: {
      fontSize: "1em",
      color: "light",
      cursor: "pointer",
      ":hover": {
        textDecoration: "none",
      },
    },
    p: {
      fontSize: "1em",
    },
    h1: {
      fontSize: ["2.5em"],
      color: "primary",
    },
    h2: {
      fontSize: ["1.8em"],
      color: "primary",
    },
    h3: {
      color: "primary",
    },
    h4: {
      fontSize: "1.3195em",
      color: "primary",
    },
    h5: {
      color: "primary",
    },
    h6: {
      color: "primary",
    },
  },
})
